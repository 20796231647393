<template>
  <div>
    <donut-chart
      :colors="getColors"
      :labels="getLabels"
      :series="getSeries"
      class="h-full"
    />
  </div>
</template>

<script>
import DonutChart from "@/components/elements/charts/DonutChart";
import LandingStatsRepository from "@/repositories/LandingStatsRepository";

export default {
  name: "LandingPickedProductsStatsDonutChart",
  components: {DonutChart},
  data: () => ({
    responseData: []
  }),
  created() {
    LandingStatsRepository
      .getPickedProductsStats()
      .then((res) => this.responseData = res.data.data);
  },
  methods: {
    getLabelByType(type) {
      switch (type) {
        case "toBePackedCount":
          return "A emballer";
        case "packedCount":
          return "Emballé";
      }
    },
    getColorByType(type) {
      switch (type) {
        case "toBePackedCount":
          return this.$luxcaddyConfig('home.charts.colors.secondary');
        case "packedCount":
          return this.$luxcaddyConfig('home.charts.colors.primary');
      }
    },
  },
  computed: {
    getSeries() {
      return this.responseData.flatMap(d => d.total);
    },
    getLabels() {
      return this.responseData.flatMap(d => this.getLabelByType(d.type));
    },
    getColors() {
      return this.responseData.flatMap(d => this.getColorByType(d.type));
    }
  },
}
</script>

<style scoped>

</style>